(function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('NavigationMenu', [], factory);
    } else {
        // Browser globals
        root.NavigationMenu = factory();
    }
}(typeof self !== 'undefined' ? self : this, function () {
    'use strict';

    function NavigationMenu(element, options) {
        this.container = element;
        this.button = this.container.querySelector('.menutoggle');

        if (!this.container || !this.button) return;

        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.toggle = this.toggle.bind(this);
        this.onopen = this.onopen.bind(this);
        this.onclose = this.onclose.bind(this);
        this.dispatch = this.dispatch.bind(this);

        this.button.addEventListener('click', this.toggle);

        document.addEventListener('click', this.close);

        this.container.addEventListener('click', function (event) {
            event.stopPropagation();
        })
    }

    NavigationMenu.prototype = {
        close: function () {
            if (!this.container.classList.contains('-open')) return;
            this.container.classList.remove('-open');
            if (typeof (this.__onclose) === 'function') {
                this.__onclose.apply(this);
            }
            this.dispatch('navigation-menu:close', {
                bubbles: true,
                cancelable: false,
                detail: {
                    component: this
                }
            });
        },
        open: function () {
            this.container.classList.add('-open');
            if (typeof (this.__onopen) === 'function') {
                this.__onopen.apply(this);
            }
            this.dispatch('navigation-menu:open', {
                bubbles: true,
                cancelable: false,
                detail: {
                    component: this
                }
            });
        },
        toggle: function (event) {
            event.stopPropagation();
            this.container.classList.toggle('-open');

            if (this.container.classList.contains('-open')) {
                if (typeof (this.__onopen) === 'function') {
                    this.__onopen.apply(this);
                }

                this.dispatch('navigation-menu:open', {
                    bubbles: true,
                    cancelable: false,
                    detail: {
                        component: this
                    }
                });
            } else {
                if (typeof (this.__onclose) === 'function') {
                    this.__onclose.apply(this);
                }

                this.dispatch('navigation-menu:close', {
                    bubbles: true,
                    cancelable: false,
                    detail: {
                        component: this
                    }
                });
            }
        },
        onopen: function (fn) {
            if (typeof (fn) !== 'function') return;

            this.__onopen = fn.bind(this);
        },
        onclose: function (fn) {
            if (typeof (fn) !== 'function') return;

            this.__onclose = fn.bind(this);
        },
        dispatch: function (name, init) {
            var newEvent;
            try {
                newEvent = new CustomEvent(name, init)
            } catch (err) {
                newEvent = document.createEvent('CustomEvent')
                newEvent.initCustomEvent(
                    name,
                    init && typeof (init.bubbles) !== 'undefined' ? init.bubbles : true,
                    init && typeof (init.cancelable) !== 'undefined' ? init.cancelable : false,
                    init ? init.detail : null
                );
            }
            this.container.dispatchEvent(newEvent);
        }
    }

    return NavigationMenu

}));
