(function () {
    'use strict';

    if (document.readyState === 'interactive') {
        setup();
    } else {
        document.addEventListener('DOMContentLoaded', setup);
    }

    function setup() {
        // image object-fit
        // https://codepen.io/yurch-html/pen/jwoMWE
        if ('objectFit' in document.documentElement.style === false) {
            Array.prototype.forEach.call(document.querySelectorAll('img[data-object-fit]'), function (image) {
                (image.runtimeStyle || image.style).background = 'url("' + image.src + '") no-repeat 50%/' + (image.currentStyle ? image.currentStyle['object-fit'] : image.getAttribute('data-object-fit'));

                image.src = 'data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'' + image.width + '\' height=\'' + image.height + '\'%3E%3C/svg%3E';
            });
        }

        browserCheck('omas');
        loadFont();
        loadComponent('navigation-menu', NavigationMenu);
        loadComponent('sticky-header', StickyHeader);

        // polyfill per la proprietà CSS video object-fit
        //if (document.querySelector('video')) objectFitVideos();

        document.addEventListener('navigation-menu:open', function () {
            document.querySelector('.omas-header-wrapper').classList.add('-menu-open');
        });

        document.addEventListener('navigation-menu:close', function () {
            document.querySelector('.omas-header-wrapper').classList.remove('-menu-open');
        });

        document.addEventListener('submit', function () {
            var disableOnSubmit = document.querySelectorAll('[data-disable-onsubmit]');

            Array.prototype.forEach.apply(disableOnSubmit, [function (el) {
                el.disabled = true;
                el.classList.add('-submitting');

                document.querySelector('body').classList.add('-submitting');
            }]);
        });

        window.addEventListener('load', function () {

        });
    }
})();
